bs-daterangepicker-container,
bs-datepicker-container {
  .bs-datepicker {
    box-shadow: none;

    .bs-datepicker-container {
      padding: 0;
      width: 100%;

      .bs-calendar-container {
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
        border-radius: 12px;
      }

      .bs-datepicker-head {
        button {
          color: var(--ta-color-text-default);
          font-weight: bold;

          &.next,
          &.previous {
            color: var(--ta-color-text-primary);
            font-weight: 500;
          }
        }
      }

      .bs-datepicker-body {
        border: none;
        min-width: 264px;

        table {
          td span:not(.is-other-month) {
            color: var(--ta-color-text-default);
          }

          td span.is-other-month {
            display: none;
          }

          td.selected span,
          td span.selected {
            background-color: var(--ta-color-text-primary);
            color: var(--ta-color-text-inverse);
          }

          td.disabled span,
          td span.disabled {
            color: var(--ta-color-text-subtle);
          }
          td span:not(.disabled):not(.selected) {
            &:hover {
              background-color: var(--ta-color-surface-neutral);
            }
          }
        }
      }
    }
  }
}
