@font-face {
  font-family: 'DMSerifDisplay';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/DMSerifDisplay/DMSerifDisplay-Regular.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'DMSerifDisplay';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/DMSerifDisplay/DMSerifDisplay-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sanomat';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/Sanomat/Sanomat-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NueueHaasGroteskTXPro';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/NeueHaasGroteskTXPro/NeueHaasGroteskTXPro-Roman.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'NueueHaasGroteskTXPro';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/NeueHaasGroteskTXPro/NeueHaasGroteskTXPro-Medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'NueueHaasGroteskTXPro';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/NeueHaasGroteskTXPro/NeueHaasGroteskTXPro-Bold.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NueueHaasGroteskDSPro';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/Sanomat/Sanomat-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/NeueHaasGroteskTXPro/NeueHaasGroteskTXPro-Roman.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Larsseit';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/NeueHaasGroteskTXPro/NeueHaasGroteskTXPro-Medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Larsseit';
  src: url('https://d35qahma2tlngp.cloudfront.net/web/fonts/NeueHaasGroteskTXPro/NeueHaasGroteskTXPro-Bold.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
}
