@mixin toastTheme($backgroundColor, $textColor) {
  background-color: $backgroundColor;
  color: $textColor;
}

.ta-toast {

  &.toast-info,
  &.toast-error,
  &.toast-warning,
  &.toast-success {
    background-image: none;
    background-color: var(--ta-color-surface-neutral);
    color: var(--ta-color-text-default);
    border-radius: 12px;
    padding: 16px 20px;
    width: auto;
    box-shadow: $boxShadow;
    cursor: pointer;
    pointer-events: auto;
  }

  &.toast-info {
    @include toastTheme(var(--ta-color-surface-neutral), var(--ta-color-text-default));
  }

  &.toast-error {
    @include toastTheme(var(--ta-color-tag-background-red), var(--ta-color-text-warning));
  }

  &.toast-warning {
    @include toastTheme(var(--ta-color-tag-background-orange), var(--ta-color-action-alert));
  }

  &.toast-success {
    @include toastTheme(var(--ta-color-text-inverse), var(--ta-color-action-success));
  }

  .toast-title {
    font-weight: 600;
  }
}