@import '@tripactions/base-styles/colors';
@import '@tripactions/base-styles/breakPoints';
@import '@tripactions/base-styles/palette';


$admFontNormal: 400;
$admFontMedium: 500;
$admFontBold: 600;

$admTransitionTimer: 100ms;

$bsColSmall: 576px;
$breakpoint-tablet-landscape: 1024px;
