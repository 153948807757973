$boxShadow: 0px 1px 4px rgba(24, 25, 27, 0.15);

// **** mat-typography ****
.mat-caption.emphasis {
  font-style: italic;
}

.mat-typography.important {
  font-style: 600;
}

// **** mat-card ****
$matCardPadding: 24px;
.mat-card:not([class*='mat-elevation-z']),
.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: $boxShadow;
}
.mat-expansion-panel {
  border-radius: 12px;
}
.mat-card.ta-card {
  border-radius: 12px;
  padding: $matCardPadding;
}

.mat-card-header {
  &.full-bleed {
    margin: (-$matCardPadding) (-$matCardPadding) 0 (-$matCardPadding) !important;
    padding: $matCardPadding $matCardPadding;
    align-items: center;
    min-height: 62px;
    justify-content: space-between;
    border-bottom: 1px solid var(--ta-color-surface-neutral);
    .mat-card-title {
      margin-bottom: 0;
    }
  }

  &.width-100 {
    width: 100%;
    .mat-card-header-text {
      width: 100%;
      margin: 0;
    }
  }
}
.suitcaseTypography .mat-card-title {
  font: 600 18px/24px NueueHaasGroteskTXPro, Roboto, emoji, sans-serif;
}
// **** mat-list ****
.custom-mat-list {
  &.mat-list-base {
    .mat-list-item {
      min-height: 60px;
      height: auto;
    }
  }

  .custom-mat-list__content {
    padding: 0 32px 16px;
  }
}

// **** mat-select ****
.sc-custom-select {
  &.mat-select {
    padding: 12px 16px;
    height: unset;
    border-width: 2px;
    border-radius: 10px;

    @media screen and (min-width: $phablet) {
      width: 240px;
    }
  }
}

.custom-select-panel {
  &.mat-select-panel {
    border-radius: 10px;

    .mat-option-text {
      display: flex;
      justify-content: space-between;
    }
  }
}

.mat-pseudo-checkbox-checked {
  background: var(--ta-color-text-primary);
}

.ta-select {
  height: 44px;
  border-radius: 12px;
  border: solid 1px var(--ta-color-divider-default);
  background-color: var(--ta-color-text-inverse);
  &:hover {
    border-color: var(--ta-color-text-subtle);
    cursor: pointer;
  }
}

.mat-select-panel {
  border-radius: 12px !important;
  max-height: 350px !important;
  .mat-option-text {
    font-weight: 500;
  }

  &.options-container {
    // !important is necessary to override material width + the option items are limited to 500px
    min-width: fit-content !important;
  }
}

// **** mat-chip ****
.custom-chip-list {
  .mat-chip.mat-standard-chip {
    background-color: var(--ta-color-text-inverse);
    border: 1px solid var(--ta-color-divider-default);
    &.mat-primary {
      border: 0;
      background-color: var(--ta-color-text-primary);
      color: var(--ta-color-text-inverse);

      &::after {
        background: transparent;
      }

      .mat-chip-remove {
        color: var(--ta-color-text-inverse);
        opacity: 0.8;
      }
    }

    @media screen and (max-width: $phablet) {
      display: flex;
    }
  }
}

// **** mat-accordion ****
mat-accordion {
  .mat-expansion-panel.ta-custom-expansion {
    &:first-of-type,
    &:last-of-type {
      border-radius: 12px;
    }
  }

  &.grid-padding {
    mat-expansion-panel-header,
    .mat-expansion-panel-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.flat-panel {
    .mat-expansion-panel,
    .mat-expansion-panel.ta-custom-expansion {
      box-shadow: none;
      border-radius: 0;
    }

    .mat-expansion-panel-header {
      padding-left: 16px;
      padding-right: 19px;

      &:hover {
        background: none !important;
      }
      &.cdk-program-focused {
        background: none !important;
      }
    }

    .mat-expansion-panel-body {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

// **** mat-checkbox ****
.ta-checkbox {
  .mat-checkbox-inner-container {
    width: 22px;
    height: 22px;
  }

  .mat-checkbox-frame,
  .mat-checkbox-background {
    border-radius: 4px;
  }
}

// **** mat-divider ****
.mat-divider {
  border-top-color: var(--ta-color-divider-default);
}

// **** mat-tab-group ****
.mat-card-tab-header {
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: $phablet) {
    height: 70px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.ta-tabgroup {
  width: 100%;
  border-bottom: 1px solid var(--ta-color-divider-default);

  &__content {
    padding: 16px;
  }

  .mat-tab-header,
  .mat-tab-labels,
  .mat-tab-group,
  .mat-card-header {
    height: 72px;
    border-bottom: 0px;
  }

  .mat-tab-label-active {
    font-weight: $admFontBold;
  }

  .mat-tab-label {
    height: 100% !important;
    padding: 0 16px !important;
    min-width: 120px !important;
    opacity: 1 !important;
    .tab-subtitle {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .mat-ink-bar {
    background-color: var(--ta-color-text-default) !important;
    height: 8px !important;
    bottom: -4px;
    border-radius: 100px;
  }

  &--centered {
    .mat-tab-labels {
      justify-content: center;
    }
  }

  &--even {
    .mat-tab-labels {
      justify-content: space-evenly;
    }
  }

  @media screen and (min-width: $tablet) {
    &__content {
      padding: 32px;
    }
  }
}

// **** mat-expansion-panel ****
.ta-custom-expansion {
  &.mat-expansion-panel:not(.ta-custom-expansion--inline) {
    margin-bottom: 16px;
  }

  .mat-expansion-indicator::after {
    padding: 4px;
  }

  a {
    color: var(--ta-color-text-primary);
  }

  .cardAvatar {
    margin-right: 8px;

    .imgContainer {
      margin: 0 !important;
      width: 36px;
      height: 36px;

      .avatar_textonly {
        width: 100%;
        height: 100%;
      }
    }
  }

  .mat-expansion-panel-header {
    font-size: 16px;
  }

  .mat-expansion-panel-body {
    padding-top: 16px;
    border-top: 1px solid var(--ta-color-surface-neutral);
  }
}

// **** mat-dialog ****
.ta-modal {
  .mat-dialog-container {
    border-radius: 12px;
    padding: 20px;
  }

  .mat-dialog-content {
    min-height: 30vh;
    margin: 0 -20px;
    padding: 0 20px;
  }

  .dialogue-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .dialogue-close {
    background: var(--ta-color-surface-neutral);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    transition: background $admTransitionTimer ease-in-out;
    margin-top: 3px;

    &:hover {
      cursor: pointer;
      background: var(--ta-color-divider-default);
    }
  }
  .dialogue-subtitle {
    font-size: 16px;
    color: var(--ta-color-text-subtle);
    font-weight: normal;
  }

  .mat-dialog-actions {
    margin: 0;
    padding: 20px 0 0 0;
    &:not(.pull-right) {
      button,
      ta-button-v2 {
        flex: 1;
      }
    }
    @media screen and (min-width: $tablet) {
      margin: 36px 0 0 0;
      padding: 0;
      display: flex;
      .action {
        flex-grow: 1;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        button,
        ta-button-v2 {
          flex: 1;
          width: 100%;
        }
      }
    }
    @media screen and (max-width: $mobile) {
      .action {
        width: 100%;
        padding-bottom: 4px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .suitcaseTypography .mat-dialog-title {
    font: 600 20px/24px NueueHaasGroteskTXPro, Roboto, emoji, sans-serif;
    margin-bottom: 0;
    @media screen and (min-width: $mobile) {
      font: 600 26px/36px NueueHaasGroteskTXPro, Roboto, emoji, sans-serif;
    }
  }

  @media screen and (min-width: $tablet) {
    .mat-dialog-container {
      padding: 40px;
    }
    .mat-dialog-content {
      margin: 0 -40px;
      padding: 0 40px;
    }
  }
}

// **** mat-slide-toggle ****
.ta-custom-toggle {
  position: relative;
  .mat-slide-toggle-bar {
    background: var(--ta-color-divider-default);
    position: relative;
    cursor: pointer;
    width: 48px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 14px;
    margin: 0;
  }

  .mat-slide-toggle-thumb-container {
    cursor: pointer;
    width: 24px;
    height: 24px;
    top: 2px;
    left: 2px;
    .mat-slide-toggle-thumb {
      width: 24px;
      height: 24px;
    }
  }

  .mat-slide-toggle-content {
    position: absolute;
    right: 10px;
    color: var(--ta-color-text-inverse);
    font-size: 16px;
    font-weight: 500;
  }

  &.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var(--ta-color-text-primary);
    }
    .mat-slide-toggle-thumb-container {
      transform: translate3d(20px, 0, 0);
      .mat-slide-toggle-thumb {
        background-color: var(--ta-color-text-inverse);
      }
    }
    .mat-slide-toggle-content {
      left: 10px;
      right: unset;
    }
  }

  &.mat-disabled {
    &.mat-slide-toggle {
      opacity: 0.5;
    }
  }
}

// **** mat-form-field ****
mat-form-field.mat-form-field.ta-input {
  .mat-form-field-wrapper {
    padding-bottom: 12px;
  }
  .mat-form-field-flex {
    border: 1px solid var(--ta-color-divider-default);
    border-radius: 12px;
    padding: 4px 12px;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-label-wrapper {
    top: -18px;
  }
  &.mat-focused,
  &.mat-form-field-should-float {
    .mat-form-field-label-wrapper {
      top: -8px;
    }
  }
  &.mat-focused {
    .mat-form-field-label {
      color: var(--ta-color-text-primary);
    }
  }
  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      margin-bottom: 12px;
    }
    .mat-form-field-flex {
      border: 1px solid var(--ta-color-text-warning);
    }
  }
  .mat-placeholder-required {
    color: var(--ta-color-text-warning);
  }
  .mat-error {
    margin: 8px 0;
  }
}

.mat-drawer-container {
  background-color: transparent;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}
// *** mat-menu ***
.ta-mat-menu.mat-menu-panel {
  border-radius: 12px;
  max-width: 300px;
  .mat-menu-item {
    font-family: NueueHaasGroteskTXPro, Roboto, emoji, sans-serif;
    color: var(--ta-color-text-primary);
    font-size: 16px;
  }
}

// *** mat-button ***
@mixin taButtonSizes {
  &.ta-button--small {
    height: 36px;
    font-size: 14px;
    line-height: 20px;
  }
  &.ta-button--large {
    height: 52px;
    font-size: 18px;
  }
}
.ta-button {
  border-radius: 12px;
  height: 44px;
  font-size: 16px;
  align-items: center;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  @include taButtonSizes;
  &--fixed-width {
    width: 200px;
  }
  &__icon {
    margin-right: 4px;
  }
  &.mat-flat-button.mat-primary[disabled] {
    color: var(--ta-color-text-inverse);
  }
  &.mat-stroked-button {
    &:not(.noThemeStyle) {
      background: var(--ta-color-text-inverse);
    }
  }
}
.suitcaseTypography .ta-button {
  font-weight: 500;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  @include taButtonSizes;
}

.mat-radio-label-content {
  white-space: initial;
}

.mat-radio-button.mat-primary.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: var(--ta-color-action-primary);
  }
  .mat-radio-inner-circle {
    background-color: var(--ta-color-action-primary);
  }
}

.mat-step-header {
  &:hover,
  &:active,
  &.cdk-program-focused {
    background-color: transparent;
  }
}

// *** mat-progress-spinner ***
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--ta-color-text-primary);
}

.mat-step-header {
  .mat-step-icon-selected,
  .mat-step-icon-state-done {
    background-color: var(--ta-color-icon-primary);
  }
}
