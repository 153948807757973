:root {
  --ta-color-palette-white: #ffffff;
  --ta-color-palette-black: #000000;
  --ta-color-palette-gray-1000: #26262e;
  --ta-color-palette-blue-700: #023df1;
  --ta-color-palette-blue-100: #eef3ff;

  // copied from fe-user-mod: https://github.com/tripactions/ta-fe-user-mod/blob/c293371a32ae9fc45194595e9ae266480e8f8f06/libs/ta-core/src/assets/styles/styles.scss
  --ta-color-palette-black: #000000;
  --ta-color-palette-blue-05: #e8e8fc;
  --ta-color-palette-blue-10: #d1d1fa;
  --ta-color-palette-blue-30: #7575f0;
  --ta-color-palette-blue-40: #4747eb;
  --ta-color-palette-blue-50: #2d2dd2;
  --ta-color-palette-blue-60: #1d1daf;
  --ta-color-palette-blue-90: #07072c;
  --ta-color-palette-dark-black: #000000;
  --ta-color-palette-dark-gray-10: #e2e2e9;
  --ta-color-palette-dark-gray-20: #c6c6d2;
  --ta-color-palette-dark-gray-30: #a9a9bc;
  --ta-color-palette-dark-gray-40: #8d8da5;
  --ta-color-palette-dark-gray-50: #70708f;
  --ta-color-palette-dark-gray-60: #5a5a72;
  --ta-color-palette-dark-gray-70: #434356;
  --ta-color-palette-dark-gray-80: #2d2d39;
  --ta-color-palette-dark-gray-90: #17171c;
  --ta-color-palette-dark-gray-95: #0b0b0e;
  --ta-color-palette-dark-green-80: #194c40;
  --ta-color-palette-dark-purple-20: #d299ff;
  --ta-color-palette-dark-purple-50: #8f00ff;
  --ta-color-palette-dark-purple-60: #7300cd;
  --ta-color-palette-dark-red-60: #c71804;
  --ta-color-palette-dark-salmon-30: #ff7466;
  --ta-color-palette-dark-white: #ffffff;
  --ta-color-palette-gray-05: #f0f0f4;
  --ta-color-palette-gray-10: #e2e2e9;
  --ta-color-palette-gray-20: #c6c6d2;
  --ta-color-palette-gray-30: #a9a9bc;
  --ta-color-palette-gray-40: #8d8da5;
  --ta-color-palette-gray-50: #70708f;
  --ta-color-palette-gray-60: #5a5a72;
  --ta-color-palette-gray-70: #434356;
  --ta-color-palette-gray-90: #17171c;
  --ta-color-palette-green-05: #ebf9f5;
  --ta-color-palette-green-10: #d9f2ec;
  --ta-color-palette-green-20: #b3e5d9;
  --ta-color-palette-green-50: #40bf9f;
  --ta-color-palette-green-60: #339980;
  --ta-color-palette-green-70: #267360;
  --ta-color-palette-green-80: #194d40;
  --ta-color-palette-orange-05: #fff0e5;
  --ta-color-palette-orange-30: #ffa666;
  --ta-color-palette-orange-40: #ff8732;
  --ta-color-palette-orange-50: #eb6200;
  --ta-color-palette-orange-60: #cc5500;
  --ta-color-palette-purple-05: #f4e5ff;
  --ta-color-palette-purple-10: #e9ccff;
  --ta-color-palette-purple-20: #d299ff;
  --ta-color-palette-purple-30: #bc66ff;
  --ta-color-palette-purple-50: #8f00ff;
  --ta-color-palette-purple-60: #7300cd;
  --ta-color-palette-purple-70: #560099;
  --ta-color-palette-purple-80: #390066;
  --ta-color-palette-red-05: #ffe8e6;
  --ta-color-palette-red-30: #fc7769;
  --ta-color-palette-red-50: #e61b05;
  --ta-color-palette-red-70: #961103;
  --ta-color-palette-salmon-30: #ff7466;
  --ta-color-palette-white: #ffffff;
  --ta-color-palette-yellow-50: #ffaa00;

  // Some old size tokens
  --ta-size-font-highlight-xsmall: 12px;
  --ta-size-font-highlight-small: 14px;
  --ta-size-font-highlight-medium: 16px;
  --ta-size-font-highlight-large: 18px;
  --ta-size-font-body-xsmall: 12px;
  --ta-size-font-body-small: 14px;
  --ta-size-font-body-medium: 16px;
  --ta-size-font-body-large: 18px;
  --ta-size-font-heading-xsmall: 18px;
  --ta-size-font-heading-small: 20px;
  --ta-size-font-heading-medium: 24px;
  --ta-size-font-heading-large: 32px;
  --ta-size-font-display-large: 42px;
  --ta-size-padding-04: 4px;
  --ta-size-padding-08: 8px;
  --ta-size-padding-12: 12px;
  --ta-size-padding-16: 16px;
  --ta-size-padding-24: 24px;
  --ta-size-padding-32: 32px;
  --ta-size-padding-40: 40px;
  --ta-size-padding-48: 48px;
  --ta-size-padding-80: 80px;
  --ta-size-padding-100: 100px;

  --ta-color-surface-brand-primary: #7300cd;
  --ta-color-surface-brand-mustard: #ffbd35;
  --ta-color-surface-brand-midnight: #151583;
  --ta-color-surface-brand-aqua: #9ddede;
}
