@import '@tripactions/base-styles/styles.scss';
@import './tokens.scss';
@import 'styles/fonts';
@import 'styles/material';
// custom styles for material components
@import 'styles/bootstrap';
@import './toastrOverrides.scss';
// custom styles for toastr
@import './dimensions.scss';
// dimensions steps
@import './rebranding.scss';
// custom styles for rebranding

@import 'flag-icon-css/css/flag-icon.css';

body {
  position: relative;
  z-index: -2;

  &.fullScreenMap {
    .onboardingHelp {
      display: none !important;
    }
  }

  &._mobileUiView {
    .download-app-splash {
      display: none !important;
    }

    .header .navContainer__active-page {
      width: 100%;
      display: block;
      text-align: left;
      left: 20px;
    }
  }
}

label {
  font-weight: $mediumFontWeight;
}

a.mat-button-base:focus {
  outline: 0;
}

.vertical-radio-group {
  display: flex;
  flex-direction: column;
}

.notification-label {
  padding: 10px 12px;
  font-size: 14px;
  color: var(--ta-color-text-success);
  background-color: var(--ta-color-surface-success);
  border-radius: 8px;

  &__icon {
    margin-right: 10px;
  }

  &--success {
    color: var(--ta-color-text-success);
    background-color: var(--ta-color-surface-success);
  }

  &--warning {
    color: var(--ta-color-text-alert);
    background-color: var(--ta-color-surface-alert);
  }

  &--actionable {
    font-weight: $heavyFontWeight;

    &:hover {
      cursor: pointer;
    }
  }
}

.notification-container {
  text-align: right;
}

.subnav-notification-tooltip {
  background: transparentize(black, .2);
  padding: 12px;

  &.bs-popover-left .arrow {
    &:after {
      border-left-color: transparentize(black, .2);
    }
  }

  &.bs-popover-bottom .arrow {
    &:after {
      border-bottom-color: transparentize(black, .2);
    }
  }

  &.bs-popover-top .arrow {
    &:after {
      border-top-color: transparentize(black, .2);
    }
  }

  &.bs-popover-right .arrow {
    &:after {
      border-right-color: transparentize(black, .2);
    }
  }

  .popover-body {
    color: var(--ta-color-text-inverse);
  }

  ul.styled {
    margin-bottom: 0;
    padding-left: 20px;
  }

  .popover-content {
    padding: 0;
  }

  &.popover {
    font-family: NueueHaasGroteskTXPro, sans-serif;
    border: 0;
    width: 280px;
    max-width: 280px;
    transform: translate(-66px, 0);
  }

  @media screen and (min-width: $wide) {
    &.popover {
      transform: translate(-140px, -30px);
    }
  }
}

.popover {
  border: none;
  font-family: NueueHaasGroteskTXPro, sans-serif;
}

.sc-caption {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;

  &--warning {
    color: var(--ta-color-action-alert);
  }
}

.btn-link {
  font-weight: $heavyFontWeight;
  color: var(--ta-color-text-primary);

  &:hover {
    color: var(--ta-color-tag-label-blue);
  }

  &:focus {
    text-decoration: none;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: 960px 0;
  }
}

.error-msg {
  font-size: 12px;
  color: var(--ta-color-text-warning);
}

@media screen and (max-width: $mobile) {
  .rightMenus {
    max-width: 140px !important;
  }
}

.container {
  &__section {
    margin: 40px auto;

    &:first-child {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $phablet) {
    &__section {
      &:first-child {
        margin-top: 40px;
      }
    }

    &__section-title {
      font-size: 20px;
    }
  }
}

.container-wide {
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (min-width: $phablet) {
    padding: 0 40px;
  }
}

// tooltip
@mixin tooltipArrow($placement, $color) {
  &.bs-tooltip-#{$placement} {
    .arrow {
      &:before {
        border-#{$placement}-color: $color !important;
      }
    }
  }
}

@mixin taTooltipBase($bgColor, $titleColor, $textColor) {
  .tooltip-inner {
    background: $bgColor;
    max-width: 300px;
    font-size: 14px;
    padding: 16px;
    border-radius: 6px;
    color: $textColor;

    .tooltip-title {
      color: $titleColor;
    }
  }
  @include tooltipArrow(left, $bgColor);
  @include tooltipArrow(right, $bgColor);
  @include tooltipArrow(top, $bgColor);
  @include tooltipArrow(bottom, $bgColor);
}

.ta-input-tooltip {
  font-family: NueueHaasGroteskTXPro, sans-serif;
  font-weight: normal;
  pointer-events: auto !important;
  margin-top: 8px;

  &.show {
    opacity: 1;
  }

  .tooltip-arrow {
    &:before {
      display: block !important;
    }
  }

  p {
    margin-bottom: 4px;
  }

  @include taTooltipBase(var(--ta-color-text-default), var(--ta-color-text-inverse), var(--ta-color-text-inverse));

  &.light {
    @include taTooltipBase(var(--ta-color-text-inverse), var(--ta-color-text-default), var(--ta-color-text-subtle));
  }
}

.subnav-container {
  display: flex;
  background: var(--ta-color-text-primary-pressed);
  padding: 12px 24px;
  color: var(--ta-color-text-inverse);
  min-height: 60px;
  align-items: center;
}

.breakWord {
  word-wrap: break-word;
}

.text-muted {
  color: var(--ta-color-text-subtle) !important;
}

.text-small, .suitcaseTypography .mat-typography .text-small {
  font-size: 14px;
}

$inputRadius: 12px;
.sc_form-control {
  border: 1px solid var(--ta-color-divider-default);
  padding: 13px 16px;
  border-radius: $inputRadius;
  height: auto;
  color: var(--ta-color-text-default);
  font-weight: 500;

  &:focus {
    color: var(--ta-color-text-default);
    background-color: var(--ta-color-text-inverse);
    border-color: var(--ta-color-text-primary);
    outline: 0;
    box-shadow: none;
    border: 2px solid var(--ta-color-text-primary);
  }

  &.ng-dirty {
    &.ng-invalid:not(.ng-pristine),
    &.errorState {
      border-color: var(--ta-color-text-warning);
    }
  }
}

.spinner-mask {
  display: block;
  background: var(--ta-color-surface-neutral);
  opacity: 0.9;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
}

// suitacase pagination style
.pagination-wrap {
  display: flex;
  justify-content: center;
  margin-top: 28px;

  .page-item {
    .page-link {
      color: var(--ta-color-text-default);
      background-color: transparent;
      border: 0;
      height: 28px;
      min-width: 28px;
      line-height: 28px;
      padding: 0;
      margin: 0 2px;
      text-align: center;
      border-radius: 50%;

      &:focus {
        box-shadow: none;
      }
    }

    &.pagination-next,
    &.pagination-prev,
    &.pagination-first,
    &.pagination-last {
      .page-link {
        font-size: 24px;
        line-height: 25px;
      }

      &.disabled {
        .page-link {
          color: var(--ta-color-tag-label-gray);
        }
      }
    }

    &.active {
      .page-link {
        color: var(--ta-color-text-inverse);
        background-color: var(--ta-color-text-default);
        border-color: var(--ta-color-text-default);
      }
    }
  }
}

.interpunct {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 4px;
  height: 4px;
  background: transparentize(#fff, .8);
  border-radius: 50%;

  &--dark {
    background: var(--ta-color-tag-label-gray);
  }
}

.text-important {
  font-weight: $admFontBold;
}

.text-medium {
  font-weight: $admFontMedium;
}

.ta-search {
  width: 100%;
  max-width: 320px;
  margin-right: 24px;
  position: relative;
  caret-color: var(--ta-color-text-primary);
  font-size: 16px;

  .form-control {
    padding-left: 52px;
  }

  &__icon {
    position: absolute;
    top: 15px;
    left: 16px;
    color: var(--ta-color-tag-label-gray);
  }
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  padding: 2px;
  background: url('./assets/sharedresources/images/svg/icon-search-clear.svg') center center no-repeat;
}

input::placeholder {
  color: var(--ta-color-text-subtle) !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  background: var(--ta-color-surface-neutral);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--ta-color-divider-default);
}

.visibility-hidden {
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
}

.ta-spinner {
  text-align: center;
}

.loader-absolute-centered {
  position: absolute;
  text-align: center;
  z-index: 3;
  top: 50%;
  left: 50%;

  &--vertical-centered {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

// override defaults from @tripactions/base-styles
a:focus {
  outline: inherit;
}

.cdk-overlay-pane:has(.mat-autocomplete-panel) {
  min-width: fit-content;
}