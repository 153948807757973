body.rebranded {
  a,
  .btn-link {
    color: var(--ta-color-text-primary);
    &:hover {
      color: var(--ta-color-text-primary-hovered);
    }
  }

  .mat-card {
    color: var(--ta-color-text-default) !important;
    background: var(--ta-color-surface-default) !important;
    opacity: 0.9 !important;
  }

  .form-control:focus {
    border-color: var(--ta-color-text-primary) !important;
  }

  .customSlider .mat-slider-thumb {
    background-color: var(--ta-color-text-primary) !important;
  }

  .dropdown-item:active {
    color: var(--ta-color-text-primary) !important;
    background-color: var(--ta-color-text-inverse) !important;
  }

  .alert-info {
    color: var(--ta-color-text-primary) !important;
    background-color: var(--ta-color-palette-purple-10) !important;
    border-color: var(--ta-color-palette-purple-20) !important;
  }

  .page-item.active .page-link {
    background-color: var(--color-text-primary-pressed) !important;
    border-color: var(--color-text-primary-pressed) !important;
  }

  .pagerNav .page-link.active {
    color: var(--ta-color-text-primary-pressed) !important;
  }

  .mat-checkbox-checked {
    .mat-checkbox-inner-container {
      .mat-checkbox-background {
        background-color: var(--ta-color-text-primary) !important;
      }
    }
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--ta-color-text-primary) !important;
  }

  .mat-pseudo-checkbox-checked {
    background-color: var(--ta-color-text-primary) !important;
  }

  .subnav-container {
    background-color: var(--ta-color-surface-neutral);
    color: var(--ta-color-text-default);
  }

  .btn:focus {
    box-shadow: 0 0 0 0.2rem rgb(57 0 102 / 25%);
  }

  button:focus-visible {
    outline: var(--ta-color-text-primary) auto 1px;
  }

  .subnav__item.mat-button-toggle-checked:after {
    background: var(--ta-color-border-primary-hovered) !important;
  }

  .subnav__item.mat-button-toggle-checked {
    background-color: var(--ta-color-action-secondary-hovered) !important;
  }

  .mat-menu-item {
    color: var(--ta-color-text-default) !important;
    opacity: 0.87 !important;
  }

  .mat-menu-item[disabled],
  .mat-menu-item[disabled]::after {
    color: var(--ta-color-text-default) !important;
    opacity: 0.38 !important;
  }

  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary {
    background: var(--ta-color-action-primary);
  }
  .mat-button.mat-primary,
  .mat-icon-button.mat-primary,
  .mat-stroked-button.mat-primary {
    color: var(--ta-color-text-primary);
  }
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background: var(--ta-color-action-primary);
  }

  .ta-menu-item {
    --select-color: var(--ta-color-text-primary);
  }

  .ta-menu-item.selected .ta-menu-item--text {
    color: var(--ta-color-text-default) !important;
  }
}
